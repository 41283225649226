export const content = {
    consent: {
      MT: {
        English: {
          title: "Welcome to our Digital Guide!",
          subtitle: "By using this app you agree to the following:",
          bulletPoints: [
            {
              title: "Experimental AI Features:",
              text: "You may encounter occasional bugs."
            },
            {
              title: "Usage Data for Improvement:",
              text: "We collect general usage data to improve the app. No personal information is collected."
            },
            {
              title: "AI-Generated Responses:",
              text: "Responses are generated by AI and may not always be fully accurate. Please verify important details."
            }
          ],
          links: {
            prefix: "For more details, please see our",
            privacy: "Privacy Policy",
            terms: "Terms & Conditions",
            connector: "and"
          }
        }
      },
      RLM: {
        English: {
          title: "Welcome to our Digital Guide!",
          subtitle: "By using this app you agree to the following:",
          bulletPoints: [
            {
              title: "Experimental AI Features:",
              text: "You may encounter occasional bugs."
            },
            {
              title: "Usage Data for Improvement:",
              text: "We collect general usage data to improve the app. No personal information is collected."
            },
            {
              title: "AI-Generated Responses:",
              text: "Responses are generated by AI and may not always be fully accurate. Please verify important details."
            }
          ],
          links: {
            prefix: "For more details, please see our",
            privacy: "Privacy Policy",
            terms: "Terms & Conditions",
            connector: "and"
          }
        },
        Welsh: {
          title: "Croeso i Ganllaw Digidol Amgueddfa Llundain Frenhinol!",
          subtitle: "Drwy ddefnyddio'r ap hwn rydych yn cytuno i'r canlynol:",
          bulletPoints: [
            {
              title: "Nodweddion AI Arbrofol:",
              text: "Efallai y byddwch yn dod ar draws gwallau achlysurol."
            },
            {
              title: "Data Defnydd ar gyfer Gwella:",
              text: "Rydym yn casglu data defnydd cyffredinol i wella'r ap. Ni chesglir unrhyw wybodaeth bersonol."
            },
            {
              title: "Ymatebion a Gynhyrchir gan AI:",
              text: "Mae ymatebion yn cael eu cynhyrchu gan AI ac efallai na fyddant bob amser yn gwbl gywir. Gwiriwch fanylion pwysig."
            }
          ],
          links: {
            prefix: "Am fwy o fanylion, gweler ein",
            privacy: "Polisi Preifatrwydd",
            terms: "Telerau ac Amodau",
            connector: "a"
          }
        }
      }
    },
    object: {
      MT: {
        English: {
          desktopMessage: {
            title: "Please return to vertical orientation",
            text: "This app is designed for vertical orientation on mobile devices only" //"Please access it from your smartphone
          },
          loading: {
            message: "Loading app...",
            subtitle: "Just a moment while we set things up",
            wifiWarning: "Make sure you are connected to our Wi-Fi"
          },
          scanning: {
            noObject: "Scan any wax figure when ready!",
            scannerError: "Sorry, scanner not working, please refresh this page.",
            images: {
              scanFigure: "/wax_figure_scan.png",
              notWorking: "/not_working_scan.png"
            }
          },
          consent: {
            button: "Agree and continue"
          }
        }
      },
      RLM: {
        English: {
          desktopMessage: {
            title: "Please return to vertical orientation",
            text: "This app is designed for vertical orientation on mobile devices only" //"Please access it from your smartphone
          },
          loading: {
            message: "Loading app...",
            subtitle: "Just a moment while we set things up",
            wifiWarning: "Make sure you are connected to our Wi-Fi"
          },
          scanning: {
            noObject: "Scan any object when ready!",
            scannerError: "Sorry, scanner not working, please refresh this page.",
            images: {
              scanFigure: "/rlm_scan.png",
              notWorking: "/not_working_scan.png"
            }
          },
          consent: {
            button: "Agree and continue"
          }
        },
        Welsh: {
          desktopMessage: {
            title: "Dychwelwch i'r cyfeiriadedd fertigol",
            text: "Mae'r ap hwn wedi'i gynllunio ar gyfer cyfeiriadedd fertigol ar ddyfeisiau symudol yn unig"
            // title: "Symudol yn Unig",
            // text: "Mae'r rhaglen hon wedi'i chynllunio ar gyfer dyfeisiau symudol yn unig. Defnyddiwch eich ffôn clyfar i gael mynediad."
          },
          loading: {
            message: "Yn llwytho'r ap...",
            subtitle: "Arhoswch funud tra'n bod ni'n paratoi",
            wifiWarning: "Gwnewch yn siŵr eich bod wedi cysylltu â'n Wi-Fi"
          },
          scanning: {
            noObject: "Sganiwch unrhyw wrthrych pan fyddwch yn barod!",
            scannerError: "Mae'n ddrwg gennym, nid yw'r sganiwr yn gweithio, adnewyddwch y dudalen hon.",
            images: {
              scanFigure: "/rlm_scan.png",
              notWorking: "/not_working_scan.png"
            }
          },
          consent: {
            button: "Cytuno a pharhau"
          }
        }
      }
    },
    camera: {
      MT: {
        English: {
          loading: "Loading Camera...",
          timeout: "Please refresh this page and ensure camera access is enabled in your browser",
          error: "Please refresh this page and ensure camera access is enabled in your browser",
          permissionDenied: "Please refresh this page and ensure camera access is enabled in your browser",
          zoom: "x"
        }
      },
      RLM: {
        English: {
          loading: "Loading Camera...",
          timeout: "Please refresh this page and ensure camera access is enabled in your browser",
          error: "Please refresh this page and ensure camera access is enabled in your browser",
          permissionDenied: "Please refresh this page and ensure camera access is enabled in your browser",
          zoom: "x"
        },
        Welsh: {
          loading: "Yn llwytho'r camera...",
          timeout: "Adnewyddwch y dudalen hon a sicrhewch fod mynediad i'r camera wedi'i alluogi yn eich porwr",
          error: "Adnewyddwch y dudalen hon a sicrhewch fod mynediad i'r camera wedi'i alluogi yn eich porwr",
          permissionDenied: "Adnewyddwch y dudalen hon a sicrhewch fod mynediad i'r camera wedi'i alluogi yn eich porwr",
          zoom: "x"
        }
      }
    },
    conversation: {
        MT: {
            English: {
                navigation: {
                    moreInfo: "Tell me more!",
                    returnToCamera: "Return to camera"
                },
                messages: {
                    thinking: "Thinking...",
                    transcribing: "Transcribing...",
                    inputPlaceholder: "Ask me any question...",
                    disclaimer: "The virtual guide can make mistakes. Check important information.",
                    stopRecording: "Tap to stop recording",
                    cancelRecording: "Cancel Recording",
                    experimentalDisclaimer: "Experimental app: expect occasional bugs",
                    error: "Oops! I seem to have had a little brain freeze there. Can you try asking me again?",
                    validation: {
                        showSources: "Show sources",
                        notValidated: "Source not validated"
                    },
                    tellMeMore: "Tell me more"
                },
                sender: {
                    user: "You",
                    assistant: "Virtual Guide"
                }
            }
        },
        RLM: {
            English: {
                navigation: {
                    moreInfo: "Tell me more!",
                    returnToCamera: "Return to camera"
                },
                messages: {
                    thinking: "Thinking...",
                    transcribing: "Transcribing...",
                    inputPlaceholder: "Ask me any question...",
                    disclaimer: "The virtual guide can make mistakes. Check important information.",
                    stopRecording: "Tap to stop recording",
                    cancelRecording: "Cancel Recording",
                    experimentalDisclaimer: "Experimental app: expect occasional bugs",
                    error: "Oops! I seem to have had a little brain freeze there. Can you try asking me again?",
                    validation: {
                        showSources: "Show sources",
                        notValidated: "Source not validated"
                    },
                    tellMeMore: "Tell me more"
                },
                sender: {
                    user: "You",
                    assistant: "Virtual Guide"
                }
            },
            Welsh: {
                navigation: {
                    moreInfo: "Dywedwch fwy!",
                    returnToCamera: "Dychwelyd i'r camera"
                },
                messages: {
                    thinking: "Yn meddwl...",
                    transcribing: "Yn trawsgrifio...",
                    inputPlaceholder: "Gofynnwch unrhyw gwestiwn...",
                    disclaimer: "Gall y tywysydd rhithwir wneud camgymeriadau. Gwiriwch wybodaeth bwysig.",
                    stopRecording: "Tapiwch i stopio recordio",
                    cancelRecording: "Canslo Recordio",
                    experimentalDisclaimer: "Ap arbrofol: disgwyliwch wallau achlysurol",
                    error: "Wps! Mae'n ymddangos bod gen i rewi bach yn yr ymennydd. Allwch chi geisio gofyn i mi eto?",
                    validation: {
                        showSources: "Dangos ffynonellau",
                        notValidated: "Ffynhonnell heb ei dilysu"
                    },
                    tellMeMore: "Dywedwch fwy"
                },
                sender: {
                    user: "Chi",
                    assistant: "Tywysydd Rhithwir"
                }
            }
        }
    },
    survey: {
      MT: {
        English: {
          title: "Your Feedback",
          questions: {
            rating: {
              text: "Would you like to see this app available throughout Madame Tussauds?",
              labels: {
                min: "Definitely not",
                middle: "Maybe",
                max: "Absolutely"
              }
            },
            feedback: {
              text: "Any other feedback about this app?",
              placeholder: "Tell us what you enjoyed or what could be improved..."
            }
          },
          buttons: {
            submit: "Submit Feedback",
            close: "Close"
          },
          thankYou: "Thank you for your feedback!"
        }
      },
      RLM: {
        English: {
          title: "Your Feedback",
          questions: {
            rating: {
              text: "How likely are you to recommend this app to friends visiting the museum?",
              labels: {
                min: "Not at all likely",
                max: "Extremely likely"
              }
            },
            feedback: {
              text: "Any other feedback about this app?",
              placeholder: "Tell us what you enjoyed or what could be improved..."
            }
          },
          buttons: {
            submit: "Submit Feedback",
            close: "Close"
          },
          thankYou: "Thank you for your feedback!"
        },
        Welsh: {
          title: "Eich Adborth",
          questions: {
            rating: {
              text: "Pa mor debygol ydych chi o argymell yr ap hwn i ffrindiau sy'n ymweld â'r amgueddfa?",
              labels: {
                min: "Ddim yn debygol o gwbl",
                max: "Yn hynod debygol"
              }
            },
            feedback: {
              text: "Oes gennych chi unrhyw adborth arall am yr ap?",
              placeholder: "Dywedwch wrthym beth wnaethoch chi ei fwynhau neu beth ellid ei wella..."
            }
          },
          buttons: {
            submit: "Cyflwyno Adborth",
            close: "Cau"
          },
          thankYou: "Diolch am eich adborth!"
        }
      }
    },
    aiInfo: {
        MT: {
            English: {
                title: "About Our AI-Powered Virtual Guide",
                intro: "Our virtual guide uses artificial intelligence to help you explore Madame Tussauds in a whole new way.",
                features: {
                    title: "Key Features:",
                    list: [
                        {
                            title: "Camera-based recognition:",
                            text: "Simply point your camera, and the guide will recognise which figure you're looking at. Your camera feed is never stored."
                        },
                        {
                            title: "Answer Your Questions:",
                            text: "Want to know more? Just ask, and the guide will provide some interesting stories. Check out the green tags to view which information has been verified by our team."
                        }
                    ]
                },
                keepInMind: {
                    title: "Keep in Mind:",
                    text: "This is an experimental app and you may experience some bugs or inaccuracies. Treat this as an exploratory experience and double-check any critical facts."
                },
                feedback: {
                    title: "Finally:",
                    text: "Don't forget to provide us your feedback, good or bad! Click the survey button in the top right corner."
                }
            }
        },
        RLM: {
            English: {
                title: "About Our AI-Powered Virtual Guide",
                intro: "Our virtual guide uses artificial intelligence to help you explore the Roman Legion Museum in a whole new way.",
                features: {
                    title: "Key Features:",
                    list: [
                        {
                            title: "Camera-based recognition:",
                            text: "Simply point your camera, and the guide will recognise which object you're looking at. Your camera feed is never stored."
                        },
                        {
                            title: "Answer Your Questions:",
                            text: "Want to know more? Just ask, and the guide will provide some interesting stories. Check out the green tags to view which information has been verified by our team."
                        }
                    ]
                },
                keepInMind: {
                    title: "Keep in Mind:",
                    text: "This is an experimental app and you may experience some bugs or inaccuracies. Treat this as an exploratory experience and double-check any critical facts."
                },
                feedback: {
                    title: "Finally:",
                    text: "Don't forget to provide us your feedback, good or bad! Click the survey button in the top right corner."
                }
            },
            Welsh: {
                title: "Am Ein Tywysydd Rhithwir sy'n Defnyddio AI",
                intro: "Mae ein tywysydd rhithwir yn defnyddio deallusrwydd artiffisial i'ch helpu i archwilio Amgueddfa Lleng Rufeinig Cymru mewn ffordd hollol newydd.",
                features: {
                    title: "Prif Nodweddion:",
                    list: [
                        {
                            title: "Adnabod yn seiliedig ar gamera:",
                            text: "Cyfeiriwch eich camera, a bydd y tywysydd yn adnabod pa wrthrych rydych chi'n edrych arno. Ni chaiff eich ffrwd camera byth ei storio."
                        },
                        {
                            title: "Ateb Eich Cwestiynau:",
                            text: "Eisiau gwybod mwy? Gofynnwch, a bydd y tywysydd yn darparu straeon diddorol. Edrychwch ar y tagiau gwyrdd i weld pa wybodaeth sydd wedi'i gwirio gan ein tîm."
                        }
                    ]
                },
                keepInMind: {
                    title: "Cofiwch:",
                    text: "Mae hwn yn ap arbrofol ac efallai y byddwch yn profi rhai gwallau neu anghywirdebau. Triniwch hwn fel profiad archwiliol a gwiriwch unrhyw ffeithiau critigol."
                },
                feedback: {
                    title: "Yn Olaf:",
                    text: "Peidiwch ag anghofio rhoi eich adborth i ni, yn dda neu'n ddrwg! Cliciwch y botwm arolwg yn y gornel dde uchaf."
                }
            }
        }
    },
    validation: {
        MT: {
            English: {
                title: "Validated Information",
                subtitle: "This AI-generated response is based on the following sources verified by Madame Tussauds. Please note that any additional information beyond these sources should be independently verified.",
                source: "Source"
            }
        },
        RLM: {
            English: {
                title: "Validated Information",
                subtitle: "This AI-generated response is based on the following sources that have been verified by the Roman Legion Museum. Please note that any additional information beyond these sources should be independently verified. You can explore more verified sources in the <a href='https://museum.wales/collections/online/' target='_blank'>Museum Wales Collections Online Database</a>.",
                source: "Source"
            },
            Welsh: {
                title: "Gwybodaeth wedi'i Dilysu",
                subtitle: "Mae'r ymateb hwn a gynhyrchwyd gan AI yn seiliedig ar y ffynonellau dilysu canlynol. Sylwch y dylid gwirio'n annibynnol unrhyw wybodaeth ychwanegol y tu hwnt i'r ffynonellau hyn. Gallwch archwilio mwy o ffynonellau wedi'u gwirio yn <a href='https://amgueddfa.cymru/casgliadau/arlein/' target='_blank'>Gronfa Ddata Casgliadau Ar-lein Amgueddfa Cymru</a>.",
                source: "Ffynhonnell"
            }
        }
    },
    npsPopup: {
      MT: {
        English: {
          title: "Quick Feedback",
          question: "How likely are you to recommend this app to a friend visiting Madame Tussauds?",
          labels: {
            min: "Not at all likely",
            max: "Extremely likely"
          }
        }
      },
      RLM: {
        English: {
          question: "How likely are you to recommend this app to friends visiting the museum?",
          labels: {
            min: "Not at all likely",
            max: "Extremely likely"
          }
        },
        Welsh: {
          question: "Pa mor debygol ydych chi o argymell yr ap hwn i ffrindiau sy'n ymweld â'r amgueddfa?",
          labels: {
            min: "Ddim yn debygol o gwbl",
            max: "Yn hynod debygol"
          }
        }
      }
    },
    expiryFeedback: {
      MT: {
        English: {
          title: "Thank you for participating in our trial!",
          intro: "If you have a moment, your feedback would be incredibly valuable.",
          questions: {
            rating: {
              text: "Would you like to see this app available throughout Madame Tussauds?",
              labels: {
                min: "Definitely not",
                middle: "Maybe",
                max: "Absolutely"
              }
            },
            feedback: {
              text: "Please share any other feedback you have for us:",
              placeholder: "Tell us what you enjoyed or what could be improved..."
            }
          },
          buttons: {
            submit: "Submit Feedback"
          },
          thankYou: "Thank you for your feedback! Please close this tab."
        }
      },
      RLM: {
        English: {
          title: "Thank you for participating in our trial!",
          intro: "If you have a moment, your feedback would be incredibly valuable.",
          questions: {
            rating: {
              text: "How likely are you to recommend this app to friends visiting the museum?",
              labels: {
                min: "Not at all likely",
                max: "Extremely likely"
              }
            },
            feedback: {
              text: "Please share any other feedback you have for us:",
              placeholder: "Tell us what you enjoyed or what could be improved..."
            }
          },
          buttons: {
            submit: "Submit Feedback"
          },
          thankYou: "Thank you for your feedback! Please close this tab."
        },
        Welsh: {
          title: "Diolch am gymryd rhan yn ein treial!",
          intro: "Os oes gennych funud, byddai eich adborth yn hynod werthfawr.",
          questions: {
            rating: {
              text: "Pa mor debygol ydych chi o argymell yr ap hwn i ffrindiau sy'n ymweld â'r amgueddfa?",
              labels: {
                min: "Ddim yn debygol o gwbl",
                max: "Yn hynod debygol"
              }
            },
            feedback: {
              text: "Rhannwch unrhyw adborth arall sydd gennych i ni:",
              placeholder: "Dywedwch wrthym beth wnaethoch chi ei fwynhau neu beth ellid ei wella..."
            }
          },
          buttons: {
            submit: "Cyflwyno Adborth"
          },
          thankYou: "Diolch am eich adborth! Caewch y tab hwn."
        }
      }
    }
  };